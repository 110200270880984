<template>
  <div>
    <!-- TODO: add loading -->
    <div
      v-if="loading"
      class="w-screen h-screen flex items-center justify-center mt-10"
    >
      <spinner class="w-[200px] h-[200px]" color="red"></spinner>
    </div>
    <div v-else>
      <div
        v-if="brokenLink"
        class="flex flex-col justify-center items-center w-screen h-screen"
      >
        <img
          class="mx-auto"
          src="@/assets/images/icons/broken_link.svg"
          alt="loading icon"
          width="168"
          height="170"
        />
        <p class="text-center fw-bold">
          Check your email for a valid link or click
          <router-link
            class="font-bold text-[blue]"
            :to="{ name: 'Admin Login' }"
            >Login</router-link
          >
        </p>
      </div>
      <guest-layout v-else>
        <!-- TODO: add not found or broken link -->
        <div class="flex items-center justify-center flex-col font-mulish">
          <h3 class="text-[#484848] text-base lg:text-2xl">Welcome</h3>
          <h2 class="font-bold text-[#0090FF]">{{ email }}</h2>

          <p class="text-[#484848] mt-5">
            You have been invited to our system as an
            <span class="font-bold text-[#1A8103]">{{ roleName }}</span>
          </p>
          <p class="text-xs mt-1">
            Please provide the details required below to proceed
          </p>

          <div class="bg-[#F9F9F9] p-5 mt-5 w-full lg:w-[698px] rounded">
            <form @submit.prevent="proceed">
              <div>
                <label for="firstName">
                  First Name<span class="req">*</span>
                </label>
                <input
                  id="firstName"
                  type="text"
                  v-uppercase
                  v-model="form.firstName"
                  class="input"
                />
              </div>
              <div>
                <label for="lastName">
                  Last Name<span class="req">*</span>
                </label>
                <input
                  id="lastName"
                  type="text"
                  v-uppercase
                  v-model="form.lastName"
                  class="input"
                />
              </div>
              <div>
                <label for="phoneNumber">
                  Phone Number<span class="req">*</span>
                </label>
                <input
                  id="phoneNumber"
                  type="text"
                  v-uppercase
                  v-model="form.phoneNumber"
                  class="input"
                />
              </div>
              <div>
                <button :disabled="proceeding || !formReady" type="submit">
                  <span>Proceed</span>
                  <spinner v-if="proceeding"></spinner>
                </button>
              </div>
            </form>
          </div>
        </div>
      </guest-layout>
    </div>
  </div>
</template>

<script>
import GuestLayout from "@/layouts/AdminOnboardingLayout.vue";
export default {
  name: "AdminOnboarding",
  components: {
    GuestLayout,
  },
  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
      },
      loading: false,
      brokenLink: true,
      proceeding: false,
      email: null,
      activationCode: null,
      userId: null,
      roleName: null,
    };
  },
  computed: {
    formReady() {
      let isOk = true;
      Object.keys(this.form).forEach((key) => {
        if (!this.form[key]) {
          isOk = false;
        }
      });
      return isOk;
    },
  },
  async beforeMount() {
    await this.verifyOnboardingToken();
  },
  methods: {
    resetForm() {
      this.form = {
        firstName: null,
        lastName: null,
        phoneNumber: null,
      };
      this.email = null;
      this.activationCode = null;
      this.userId = null;
    },
    async proceed() {
      try {
        this.proceeding = true;

        const res = await this.$http.post(
          "/users/onboard-non-staff",
          {
            ...this.form,
            email: this.email,
            userId: this.userId,
            activationCode: this.activationCode,
          },
          {
            headers: { noauth: true },
          }
        );

        if (!res) {
          this.proceeding = false;
          return;
        }

        const { data } = res;

        this.$router.push({ name: "Admin Create Password" });

        this.proceeding = false;
        this.resetForm();

        this.$swal({
          text: data.message,
          icon: "success",
        });
      } catch (error) {
        console.log({ error });
        this.proceeding = false;
      }
    },

    async verifyOnboardingToken() {
      try {
        this.loading = true;
        const payload = this.$route.query;
        const res = await this.$http.post("/users/verify-token", payload, {
          headers: { noauth: true },
        });

        if (!res) {
          this.loading = false;
          this.brokenLink = true;
          return;
        }

        const { data } = res;

        this.$store.commit("admin/saveData", {
          key: "onboardData",
          data: data.data,
        });

        if (data.data.firstName && data.data.lastName && !data.data.isActive) {
          this.$router.push({ name: "Admin Create Password" });
        }

        this.email = data.data.email;
        this.userId = data.data._id;
        this.activationCode = data.data.activationCode;
        this.roleName = data.data.roleName;
        this.loading = false;
        this.brokenLink = false;
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
.req {
  @apply text-red-400;
}
input {
  @apply uppercase;
  height: 40px;
}
form > div:not(:first-child) {
  @apply mt-4;
}
form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
</style>
